.CohortCard {
    background-color: $white;
    box-shadow: 0px 5px 10px rgba(101, 101, 101, 0.05), 0px 5px 10px rgba(61, 61, 61, 0.1);
    border-radius: 4px;
    box-sizing: border-box;
    height: 350px;
    margin: 16px;
    padding: 16px 20px;
    width: 510px;

    &__Content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        &__Title {
            overflow-wrap: anywhere;
            h2 {
                font-weight: 700;
                font-size: 1.4em; } }

        &__Details {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-end;

            &__Info {
                width: 100%;

                &__Tag {
                    align-items: center;
                    display: flex;
                    width: 100%; } }

            &__Date {
                color: $grey;
                display: flex;
                margin-left: 5px;
                p {
                    font-size: 0.8em; } }

            &__Labels {
                gap: 12px;
                margin-top: 12px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                span {
                    color: #391DDD;
                    margin-left: 38px;
                    text-decoration: underline;
                    font-size: 12px;
                    cursor: pointer;
                    font-family: 'Rubik' !important; } }

            &__Total {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-bottom: 15px;
                align-self: flex-start;
                h2 {
                    color: $primary;
                    font-weight: 400;
                    font-size: 2.6em; }
                h4 {
                    color: $grey;
                    font-weight: 400;
                    font-size: 1.2em; } } }

        &__Actions {
            display: flex;
            justify-content: flex-end;
            width: 100%;

            &__Button {
                margin-right: 10px !important; }

            &__Label {
                color: $grey;
                font-style: italic;
                font-weight: 600 !important; } } } }
