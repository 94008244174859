
.Tag {
    &__PENDING, &__WAITING, &__ACCEPTED, &__ACTIVATED, &__DENIED, &__VALIDATING {
        display: flex;
        align-items: center;
        justify-content: space;
        padding: 5px 6px;
        border: 1px solid;
        box-sizing: border-box;
        border-radius: 100px;
        width: 85px;
        min-width: fit-content;
        height: 26px;
        letter-spacing: 0.2px;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;
        span {
            margin-left: 5px; } }
    &__PENDING, &__WAITING, &__VALIDATING {
        background-color: #FFF2C5;
        color: #F2C111; }

    &__ACCEPTED, &__ACTIVATED {
        background-color: #D6EEB6;
        color: #75BA1D; }

    &__DENIED {
        background-color: #FDB7B7;
        color: #E10000; } }
