.CohortList {
    margin: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 1098px;
    min-height: calc( 100vh - 260px ); }

.StatusSelector {
    margin-top: 25px; }

.ModalRequestCohort {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: space-between;
    outline: none;

    &__Buttons {
        margin-top: 25px;
        display: flex;
        justify-content: flex-end; }

    &__Button {
      width: 100%;
      margin-top: 18px; }

    &__Icon {
      margin: 24px 0 20px 0;

      &__Success {
        color: #391DDD; }

      &__Error {
        color: #ff5477; } } }

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; } }

.TextArea {
    max-width: 346px;
    width: 336px;
    margin: 0px;
    min-height: 146px;
    resize: none;
    border: 1px solid #B8B5C6;
    border-radius: 4px;
    padding: 8px;
    box-sizing: border-box;
    font-family: 'Rubik';

    &__Invalid {
        border: 1px solid #ff5477; }

    @include placeholder {
        color: #9e9e9e;
        font-style: italic; } }

