.CohortFilter {
  &__Filters {
    &__Item {
      margin: 40px 0;

      &__Dropdown {
        p {
          font-family: 'Lato';
          font-weight: bold; }

        .MuiSelect-root {
          min-width: 320px; } }

      &__Chips {
        width: 100%;
        max-width: 350px;
        margin: 10px 0;
        min-width: 320px; } } } }
