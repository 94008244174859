.View {
  &__Background {
    background-color: $grey-light;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    min-height: calc( 100vh - 100px );
    width: 100%;

    &--header-larger {
        min-height: calc( 100vh - 260px ); }

    &__Sides {
      display: flex;
      width: 255px; } }

  &__Content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &--centered {
      margin-top: 55px;
      width: 452px; } }

  &__Title {
    align-items: center;
    display: flex;

    &__Icon {
      margin-right: 10px; }

    h2 {
      color: $black;
      font-family: 'Rubik' !important;
      font-size: 1.6em !important;
      font-weight: 700; } }

  &__Subtitle {
    h3 {
      color: $grey;
      font-family: 'Rubik' !important;
      font-size: 1em !important;
      font-weight: 500; } } }

.clickup-chrome-ext_installed {
  margin: 0 !important; }
