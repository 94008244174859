.Login {
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__Content {
        min-width: 328px;
        min-height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        &__Fields {
            width: 100%;
            min-height: 130px;
            display: flex;
            flex-direction: column;
            justify-content: space-around; }

        &__Logo {
            width: 177px;
            height: 29px; }

        &__Button {
            width: 100%; } } }
