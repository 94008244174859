.ModalAction {
    position: fixed;
    width: 280px;
    height: fit-content;
    border-radius: 4px;
    border-color: $white;
    background-color: $white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    outline: none;
    left: calc( 50vw - 170px );
    top: calc( 50vh - 150px );

    h3 {
        font-size: 1.2em;
        font-weight: 500;
        margin-bottom: 15px; }

    h4 {
        font-size: 1.0em;
        font-weight: 400;
        color: $grey;
        margin-bottom: 15px; }

    &__Content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__Icon {
            &__Primary {
                color: #391DDD;
                margin: 30px 0px; }
            &__Secondary {
                color: #ff5477;
                margin: 30px 0px; } } }

    &__Buttons {
        margin-top: 25px;
        display: flex;
        justify-content: flex-end; }

    &__Error {
        margin: 15px 0 0;
        h4 {
            color: $red; } } }
