
.PreviewCohort {
    width: 510px !important;
    align-self: flex-start;
    min-height: calc( 100vh - 100px );

    &__Details {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__Labels {
            display: flex;
            flex-direction: column;
            margin: 16px 0;
            justify-content: flex-start; }

        &__Limit {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            h4 {
                color: $purple;
                font-size: 1em;
                font-weight: 500; }

            &__Total {
                display: flex;
                align-items: flex-end;

                h1 {
                    color: $primary;
                    font-size: 3.0em;
                    font-weight: 700; }

                h2 {
                    color: $grey;
                    font-size: 1.5em;
                    font-weight: 400;
                    margin-bottom: 8px; }

                &__Icon {
                    color: $primary;
                    margin: 15px 10px;
                    cursor: pointer; } } } }

    &__Button {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 5px; }

    &__Error {
        color: $red;
        margin: 15px 0; } }

.ModalPreviewCohort {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: space-between;
    outline: none;

    p {
        color: #ff5477;
        font-size: 14px; }

    &__Buttons {
        margin-top: 25px;
        display: flex;
        justify-content: flex-end; } }
