.Header {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100px;
  justify-content: center;
  padding: 0 72px;
  width: 100%;

  &__Container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%; }

  &__Block {
    min-width: 100px; }

  &__Title {
    font-family: 'Montserrat' !important;
    font-size: 2em !important;
    font-weight: bold !important; }

  &__Subtitle {
    color: $grey;
    font-family: 'Rubik' !important;
    font-size: 1em !important; }

  &__Back {
    align-items: center;
    cursor: pointer;
    display: flex;

    &__Icon {
      fill: $grey !important; }

    &__Text {
      color: $grey;
      font-family: 'Rubik' !important;
      font-size: 1em !important;
      margin-left: 5px; } }

  &__Button {
    background-color: $grey-dark !important;
    color: $white !important;
    font-family: 'Rubik' !important;
    font-size: 0.8em !important;
    margin-left: 5px;

    &:hover {
      background-color: $grey !important; } } }
