.HeaderHome {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 52px 72px;

    &__Container {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%; }

    &__Left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 227px;

        &__Title {
            margin-bottom: 17px; } }

    &__Right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        height: 100%;
        width: 50%;

        &__Dynamic {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
            width: 100%;

            &__Button {
                margin-left: 24px;
                width: 195px; } }

        &__Logout {
            margin-bottom: 60px; }

        &__Buttons {
            width: 195px;
            margin: 30px 0 15px 0; } }

    &__CohortCounter {
        color: $grey; } }
