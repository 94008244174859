
.Label {
    display: flex;
    align-items: center;
    background-color: '#0000';
    &__Content {
        background-color: $secundary;
        border-radius: 20px;
        box-sizing: border-box;
        cursor: pointer;
        margin: 0 5px;
        padding: 5px 8px;
        width: fit-content;
        h4 {
            color: $primary !important;
            font-family: 'Rubik' !important;
            font-size: 1em !important;
            font-weight: 500 !important;
            margin-bottom: 0px !important; } } }
