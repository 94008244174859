.StatusSelector {
  border: 1px solid #9E9E9E;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  width: 468px;

  &__Button {
    align-items: center;
    border-radius: 5px;
    color: $grey;
    cursor: pointer;
    display: flex;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    height: 36px;
    justify-content: center;
    width: 152px;

    &:hover {
      background-color: #D0CEE0; }

    &--active {
      @extend .StatusSelector__Button;
      background-color: #E9E6FF;
      color: $primary;

      &:hover {
        background-color:  #E9E6FF; } } } }


