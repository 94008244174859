.FunctionCard {
  background-color: $white;
  box-shadow: 0px 5px 10px rgba(101, 101, 101, 0.05), 0px 5px 10px rgba(61, 61, 61, 0.1);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  height: 100px;
  margin: 16px 0;
  padding: 16px 20px;
  width: 100%;

  &:first-of-type {
    margin-top: 0; }

  &:last-of-type {
    margin-bottom: 0; }

  &__Content {
    display: flex;
    justify-content: space-between;
    width: 100%; }

  &__Block {
    width:  100%;

    &:last-of-type {
      margin-left: 15px; }

    &__Left {
      h4 {
        color: #666666;
        font-family: 'Rubik' !important;
        font-size: 1em;
        font-weight: 600;
        text-transform: capitalize; } }

    &__Right {
      align-items: flex-end;
      display: flex;
      flex-direction: column;

      h4 {
        color: $primary;
        font-family: 'Rubik' !important;
        font-size: 1.8em; }
      p {
        color: $grey;
        font-family: 'Rubik' !important;
        font-size: 1em; } } } }
